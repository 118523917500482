import { createSlice } from '@reduxjs/toolkit'
import { fetchPosts } from './actions'
import { IPost } from './types'

export interface IPostsState {
  posts: IPost[]
  isLoadingPosts: boolean
  page: number
  pageCount: number
}

const initialState: IPostsState = {
  posts: [],
  isLoadingPosts: false,
  page: 0,
  pageCount: 0,
}

const slice = createSlice({
  name: 'posts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.isLoadingPosts = true
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.isLoadingPosts = false
        state.pageCount = action.payload.totalCount
        state.page = action.payload.page
        state.posts = action.payload.data.sort(
          (a: { date: Date }, b: { date: Date }) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime()
          },
        )
      })
      .addCase(fetchPosts.rejected, (state) => {
        state.isLoadingPosts = false
      })
  },
})
export default slice.reducer
