import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { Footer } from '../../components/Footer/footer'
import {Logo} from "../../components/Logo/logo";

export const PageNotFound = () => {
  return (
    <Flex
      bg="gray.100"
      flexDirection="column"
      height="100vh"
      w="100vw"
      alignItems="center"
      justifyContent="center"
    >
      <Logo w="40vw"/>
      <Box fontSize="32px" color="brand.500">Fly PR</Box>
      <Flex textAlign="center" fontSize="42px" color="brand.500">
        Strona nie znaleziona
      </Flex>
      <Flex fontSize="20px">
        Kliknij
        <Text
          m="0 5px"
          color="rgb(255, 162, 0)"
          _hover={{ color: 'brand.500' }}
        >
          <Link to="/">tutaj</Link>
        </Text>
        aby wrócić do strony głównej
      </Flex>
      <Footer pos="absolute" bottom="0" />
    </Flex>
  )
}
