import { Flex, Box, Checkbox, Divider, Text } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { ICategories } from '../../features/categories/slice'
import { useAsyncDebounce } from 'react-table'

enum FILTER_OPTIONS {
  MOBILE,
  DESKTOP,
}

export const Filters = ({
  usedFilters,
  possibleFilters,
  setSelectedFilters,
  onCloseDrawer,
  isMobile,
  isPost,
  onElementClick,
}: {
  usedFilters: ICategories[]
  setSelectedFilters: any
  possibleFilters: ICategories[]
  onCloseDrawer: () => void
  isMobile: boolean
  isPost?: boolean
  onElementClick?: (selected: any[]) => void
}) => {
  const onChangeCategory = useAsyncDebounce(() => {
    onCloseDrawer()
  }, 300)

  const filterType = useMemo(() => {
    if (isMobile) {
      return FILTER_OPTIONS.MOBILE
    }
    return FILTER_OPTIONS.DESKTOP
  }, [isMobile])

  const filterOptions = {
    [FILTER_OPTIONS.DESKTOP]: () => (
      <Flex maxW="300px" h="100%" w={isPost ? '250px' : ''}>
        <Box>
          {possibleFilters
            .filter(({ parentCategory }) => !!parentCategory)
            .map((element, index) => {
              const isChecked = usedFilters.some(
                (el) => el.name === element.name,
              )
              return (
                <Checkbox
                  w="100%"
                  mt="2"
                  key={`checkbox_${index}`}
                  isChecked={isChecked}
                  onChange={(e) => {
                    setSelectedFilters(
                      isChecked
                        ? usedFilters.filter((el) => el.name !== element.name)
                        : [element],
                    )
                    if (onElementClick) {
                      onElementClick([element])
                    }
                    onChangeCategory()
                  }}
                >
                  <Box fontSize="16px" fontWeight="400" alignItems="center">
                    {element.name}
                  </Box>
                </Checkbox>
              )
            })}
        </Box>
        {!isPost && <Divider orientation="vertical" h="100%" />}
      </Flex>
    ),
    [FILTER_OPTIONS.MOBILE]: () => {
      const filteredPosts = possibleFilters.filter(
        ({ parentCategory }) => !!parentCategory,
      )
      return (
        <Flex w="100%" flexWrap="wrap" gap="3" my="7">
          {filteredPosts.map((element, index) => {
            const isChecked = usedFilters.some((el) => el.name === element.name)
            return (
              <>
                <Text
                  cursor="pointer"
                  color={isChecked ? 'brand.500' : 'black'}
                  textDecoration={isChecked ? 'underline' : 'none'}
                  fontSize="xs"
                  onClick={() => {
                    setSelectedFilters(
                      isChecked
                        ? usedFilters.filter((el) => el.name !== element.name)
                        : [element],
                    )
                    onChangeCategory()
                  }}
                >
                  {element.name}
                </Text>
                {index + 1 < filteredPosts.length && (
                  <Divider orientation="vertical" />
                )}
              </>
            )
          })}
        </Flex>
      )
    },
  }

  return filterOptions[filterType]()
}
