import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ScaleFade,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { WhiteContainer } from '../../components/WhiteContainer/whiteContainer'
import { useSelector, useDispatch } from 'react-redux'
import { IRootState } from '../../features/rootSlice'
import { Filters } from '../../components/Filters/filters'
import { SearchIcon } from '@chakra-ui/icons'
import { useAsyncDebounce } from 'react-table'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchPosts, resultsPerPage } from '../../features/posts/actions'
import ListPosts from '../../components/Posts/posts'
import { isEqual } from 'lodash'
import { mobileBreakPoint } from '../../components/Navbar/navbar'
import Pagination from '../../components/Pagination/pagination'
export const emptyQuery = {
  search: '',
  categories: [],
}
const ListPage = () => {
  const [selectedFilters, setSelectedFilters] = useState([])
  const [isQueryChecked, setIsQueryChecked] = useState(false)
  const [queryElement, setQueryElement] = useState(emptyQuery)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [isMobile] = useMediaQuery(mobileBreakPoint)
  const toast = useToast()

  const getUrlData = () => {
    const urlSearchParams = new URLSearchParams(location.search)
    const query = Object.fromEntries(urlSearchParams.entries())
    if (query && query.query) {
      try {
        const atobQuery = atob(query.query)
        const parsedQuery = JSON.parse(atobQuery)
        return parsedQuery
      } catch (e) {
        toast({
          title: 'Niepoprawny url',
          position: 'bottom-left',
          status: 'error',
          isClosable: true,
        })
        navigate('/')
        return false
      }
    }
    return false
  }

  useEffect(() => {
    if (isQueryChecked && queryElement) {
      const data = getUrlData()
      if (
        (queryElement.search !== '' || queryElement.categories.length > 0) &&
        !data
      ) {
        setQueryElement(emptyQuery)
        assignNavigation(emptyQuery)
        searchPosts(emptyQuery)
      }
    }
  }, [location, isQueryChecked])

  useEffect(() => {
    const data = getUrlData()
    const newQuery = {
      search: '',
      categories: [],
    }
    if (data && data.search) {
      setSearchText(data.search)
      newQuery.search = data.search
    }
    if (data && data.categories) {
      setSelectedFilters(data.categories)
      newQuery.categories = data.categories
    }
    setQueryElement(newQuery)
    setIsQueryChecked(true)
  }, [])

  const { categories, status } = useSelector(
    (state: IRootState) => state.categories,
  )
  const assignNavigation = (query: { search: string; categories: any[] }) => {
    if (query.categories.length === 0 && query.search === '') {
      navigate('/', { replace: true })
    } else {
      navigate(
        `/?query=${btoa(unescape(encodeURIComponent(JSON.stringify(query))))}`,
      )
    }
  }

  const [searchText, setSearchText] = useState('')
  const onInputSearchClick = useAsyncDebounce(() => {
    if (queryElement.search !== searchText) {
      const newQuery = { ...queryElement, search: searchText }
      assignNavigation(newQuery)
      setQueryElement(newQuery)
      searchPosts(newQuery)
    }
  }, 150)

  const handleInputChange = (event: any) => {
    const { value } = event.target
    setSearchText(value)
  }

  const onCloseDrawer = useAsyncDebounce(() => {
    if (!isEqual(selectedFilters, queryElement.categories)) {
      const newQuery = { ...queryElement, categories: selectedFilters }
      assignNavigation(newQuery)
      setQueryElement(newQuery)
      searchPosts(newQuery)
    }
  }, 100)

  const searchPosts = (query?: { search: string; categories: any[] }) => {
    if (query) {
      // @ts-ignore
      dispatch(fetchPosts({ ...query, page: 1 }))
    } else {
      // @ts-ignore
      dispatch(fetchPosts({ ...queryElement, page: 1 }))
    }
  }
  useEffect(() => {
    if (status === 'succeeded' && isQueryChecked) {
      searchPosts()
    }
  }, [isQueryChecked, status])

  const { isLoadingPosts, page, pageCount } = useSelector(
    (state: IRootState) => state.posts,
  )

  return (
    <ScaleFade initialScale={0.001} in={true}>
      <WhiteContainer>
        <Flex flexDirection="column" h="100%" w="100%" gap="3">
          <Box
            fontSize="lg"
            fontWeight="semibold"
            maxW="230px"
            px="2"
            bg="blue.200"
            borderRadius="md"
          >
            Komunikaty prasowe
          </Box>

          <Flex gap="3" w="100%" h="100%">
            {!isMobile && (
              <Box mt="50px">
                <Filters
                  isMobile={isMobile}
                  onCloseDrawer={onCloseDrawer}
                  possibleFilters={categories}
                  usedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                />
              </Box>
            )}

            <Box gap="2" w="100%" h="100%">
              <Flex gap="2">
                {!isMobile && (
                  <Text fontSize="sm" mt="2" fontWeight="semibold">
                    Szukaj komunikatów:
                  </Text>
                )}

                <InputGroup w="xs" mb="5">
                  <InputLeftElement pointerEvents="auto">
                    <SearchIcon cursor="auto" color="#a0aec0" boxSize="5" />
                  </InputLeftElement>
                  <Input
                    value={searchText}
                    onChange={handleInputChange}
                    bg="white"
                    fontSize="sm"
                    placeholder="Wpisz słowo kluczowe"
                  />
                  {isLoadingPosts && (
                    <InputRightElement pointerEvents="none">
                      <Spinner />
                    </InputRightElement>
                  )}
                </InputGroup>
                <Button onClick={onInputSearchClick}>Szukaj</Button>
              </Flex>

              {isMobile && (
                <Filters
                  isMobile={isMobile}
                  onCloseDrawer={onCloseDrawer}
                  possibleFilters={categories}
                  usedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                />
              )}
              <Flex
                w="100%"
                h={`calc(100vh - ${isMobile ? '390px' : '290px'})`}
                justifyContent="space-between"
                flexDirection="column"
              >
                <ListPosts isMobile={isMobile} />
                <Pagination
                  onPageChange={(selectedPage) =>
                    dispatch(
                      // @ts-ignore
                      fetchPosts({ ...queryElement, page: selectedPage }),
                    )
                  }
                  page={page}
                  maxPage={Math.ceil(pageCount / resultsPerPage)}
                />
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </WhiteContainer>
    </ScaleFade>
  )
}

export default ListPage
