import { Flex, FlexProps } from '@chakra-ui/react'
import { ReactNode } from 'react'
export const footerHeight = (document: any) =>
  document.getElementById('footer')
    ? `${document.getElementById('footer')?.offsetHeight}px`
    : '0px'

export const WhiteContainer = ({
  children,
  props,
}: {
  children?: ReactNode
  props?: FlexProps
}) => {
  return (
    <Flex
      p={{ base: '0', sm: '6' }}
      borderRadius="var(--chakra-radii-lg)"
      boxShadow="var(--chakra-shadows-sm)"
      bg="whiteAlpha.900"
      direction="column"
      w="full"
      h="100%"
      alignItems="center"
      pt="4"
      {...props}
    >
      {children}
    </Flex>
  )
}
