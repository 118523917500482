import {
  Box,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Image,
  Text,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IRootState } from '../../features/rootSlice'
import { useNavigate } from 'react-router-dom'
import { blueScroll } from '../../utils/scroll'
import { format } from 'date-fns'
const currentCategoryId: any = process.env.REACT_APP_CATEGORY_ID

enum POST_OPTIONS {
  LOADING,
  EMPTY,
  POST,
}
const ListPosts = ({ isMobile }: { isMobile: boolean }) => {
  const navigate = useNavigate()

  const { isLoadingPosts, posts } = useSelector(
    (state: IRootState) => state.posts,
  )
  const { categories } = useSelector((state: IRootState) => state.categories)

  const postType = useMemo(() => {
    if (isLoadingPosts) {
      return POST_OPTIONS.LOADING
    } else if (posts.length > 0) {
      return POST_OPTIONS.POST
    }
    return POST_OPTIONS.EMPTY
  }, [isLoadingPosts, isMobile])

  const postOptions = {
    [POST_OPTIONS.POST]: () => (
      <Grid
        py="5"
        maxH={`calc(100vh - ${isMobile ? '450px' : '350px'})`}
        overflow="scroll"
        css={blueScroll}
        p="0 10px"
        overflowX="hidden"
        templateColumns={{
          base: '1fr',
          md: 'repeat(1, 1fr)',
        }}
        gap={4}
      >
        {posts
          .filter(
            ({ acf }) =>
              acf && acf.obrazek_wyrozniajacy && acf.obrazek_wyrozniajacy.url,
          )
          .map(
            ({
              id,
              title,
              date,
              content,
              categories: postCategories,
              acf: {
                obrazek_wyrozniajacy: { url },
              },
            }) => {
              const tags = categories
                .filter(
                  ({ id }) =>
                    id != currentCategoryId &&
                    postCategories.some((el: any) => el === id),
                )
                .map(({ name }) => name)
              return (
                <GridItem
                  cursor="pointer"
                  onClick={() => navigate(`/post/${id}`)}
                  _hover={{ bg: 'rgba(0, 0, 0, 0.1)', borderRadius: 'md' }}
                  key={`post_element_${id}`}
                >
                  <Flex flexWrap="wrap" gap="2" p="2">
                    <Image
                      mt="15px"
                      maxW="200px"
                      borderRadius="md"
                      objectFit="contain"
                      src={url}
                      objectPosition="top"
                      alt={`post_element_${id}`}
                    />

                    <Box p="1" flex="1">
                      {title && title.rendered && (
                        <Text py="2" fontSize="xs" fontWeight="semibold">
                          {title.rendered.replace('&#8211;', '- ')}
                        </Text>
                      )}
                      {tags && tags.length > 0 && (
                        <Flex
                          fontSize="2xs"
                          textOverflow="ellipsis"
                          overflow="hidden"
                        >
                          <Text color="gray.500">Tags: </Text>
                          <Text color="gray.500" ml="1">
                            {tags.join(', ')}
                          </Text>
                        </Flex>
                      )}
                      {date && (
                        <Flex
                          fontSize="2xs"
                          textOverflow="ellipsis"
                          overflow="hidden"
                        >
                          <Text color="gray.500">Informacja prasowa: </Text>
                          <Text color="gray.500" ml="1">
                            {format(new Date(date), 'dd-MM-yyyy')}
                          </Text>
                        </Flex>
                      )}

                      {content && content.rendered && (
                        <Text py="2" fontSize="xs">
                          {content.rendered
                            .replace('&#8211;', '- ')
                            .replace(/<\/?[^>]+(>|$)/g, '')}
                        </Text>
                      )}
                    </Box>
                  </Flex>
                </GridItem>
              )
            },
          )}
      </Grid>
    ),
    [POST_OPTIONS.EMPTY]: () => (
      <Flex w="100%" justifyContent="center" alignItems="center" h="100%">
        Niestety nie ma komunikatu spełniającego te kryteria
      </Flex>
    ),
    [POST_OPTIONS.LOADING]: () => (
      <Flex justifyContent="center" alignItems="center" w="100%" h="100%">
        <Spinner size="xl" />
      </Flex>
    ),
  }

  return postOptions[postType]()
}

export default ListPosts
