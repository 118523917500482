import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const apiUrl: any = process.env.REACT_APP_API + 'posts/'

export const getOnePostAPI = (id: string) => {
  return axios.get(apiUrl + id)
}
export const fetchOnePost = createAsyncThunk(
  'posts/fetchOnePost',
  async (id: string) => {
    const response = await getOnePostAPI(id)
    return response.data
  },
)
