import { createSlice } from '@reduxjs/toolkit'
import { fetchOnePost } from './actions'
import { IPost } from '../posts/types'

export interface IOnePostState {
  currentPost: IPost | undefined
  isLoadingOnePost: boolean
}

const initialState: IOnePostState = {
  currentPost: undefined,
  isLoadingOnePost: false,
}

const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnePost.pending, (state) => {
        state.isLoadingOnePost = true
      })
      .addCase(fetchOnePost.fulfilled, (state, action) => {
        state.isLoadingOnePost = false
        state.currentPost = action.payload
      })
      .addCase(fetchOnePost.rejected, (state) => {
        state.isLoadingOnePost = false
      })
  },
})
export default slice.reducer
