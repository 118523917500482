import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Image,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import React from 'react'
import { IPost } from '../../features/posts/types'
import { BsFiletypeTxt } from 'react-icons/bs'
import { BsFiletypeDoc } from 'react-icons/bs'
import { BsFiletypePdf } from 'react-icons/bs'

const AttachmentsForPost = ({ post }: { post: IPost }) => {
  const { acf, content } = post
  let attachmentsUrls: { url: string; title: string }[] = []
  Object.keys(acf).forEach((el) => {
    // @ts-ignore
    const element = acf[el]
    if (el.includes('zalacznik') && element) {
      attachmentsUrls.push({
        url: element.url,
        title: element.title,
      })
    }
  })
  return attachmentsUrls.length > 0 ? (
    <Box mt="25px" mr="4">
      <Divider mb="25px" />
      <Text mb="3" fontSize="md" fontWeight="bold">
        Pliki do pobrania:
      </Text>
      {/*<Flex gap={4} flexWrap="wrap" justifyContent="space-between">*/}
      <Grid
        gap="3"
        templateColumns={{
          base: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        {attachmentsUrls.map(({ url, title }, index) => {
          const isImage = /\.(jpeg|jpg|png)$/i.test(url)
          const isTxt = /\.(txt)$/i.test(url)
          const isDoc = /\.(doc|docx)$/i.test(url)
          const isPdf = /\.(pdf)$/i.test(url)
          if (isImage) {
            return (
              <Flex
                key={`attachment_${title}_${url}_${index}`}
                border="1px solid"
                borderColor="brand.400"
                borderRadius="md"
                p="4"
                alignItems="center"
                flexDirection="column"
                justifyContent="space-around"
                w="170px"
                h="170px"
              >
                <Image
                  borderRadius="md"
                  objectFit="contain"
                  src={url}
                  objectPosition="top"
                  alt="obrazek"
                  w="100px"
                  h="100px"
                />
                <Button
                  target="_blank"
                  as="a"
                  download
                  href={url}
                  w="100%"
                  size="sm"
                >
                  Pobierz
                </Button>
              </Flex>
            )
          } else if (isTxt || isDoc) {
            return (
              <Flex
                key={`attachment_${title}_${url}_${index}`}
                w="170px"
                h="170px"
                border="1px solid"
                borderColor="brand.400"
                borderRadius="md"
                p="4"
                flexDirection="column"
                justifyContent="space-around"
              >
                <Flex
                  alignItems="center"
                  flexDirection="column"
                  h="100px"
                  justifyContent="space-between"
                >
                  {isTxt && <BsFiletypeTxt fontSize="50" fontWeight="bold" />}
                  {isDoc && <BsFiletypeDoc fontSize="50" fontWeight="bold" />}
                  {isPdf && <BsFiletypePdf fontSize="50" fontWeight="bold" />}
                  <Tooltip
                    borderRadius="10px"
                    hasArrow
                    background="brand.300"
                    color="white"
                    label={
                      <Box maxW="160px" p="2">
                        {title}
                      </Box>
                    }
                  >
                    <Text
                      cursor="text"
                      my="2"
                      fontSize="sm"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      textAlign="center"
                      whiteSpace="nowrap"
                      width="100%"
                      maxWidth="100%"
                      wordBreak="break-word"
                    >
                      {title}
                    </Text>
                  </Tooltip>
                </Flex>

                <Button as="a" download href={url} size="sm">
                  Pobierz
                </Button>
              </Flex>
            )
          }
          return ''
        })}
      </Grid>
    </Box>
  ) : (
    <></>
  )
}

export default AttachmentsForPost
