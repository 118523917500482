import { createSlice } from '@reduxjs/toolkit'
import { fetchCategories } from './actions'
export interface ICategories {
  name: string
  slug: string
  id: string
  parentCategory?: string | number
}

export interface ICategoriesState {
  categories: ICategories[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

const initialState: ICategoriesState = {
  categories: [],
  status: 'idle',
}

const currentCategoryId: any = process.env.REACT_APP_CATEGORY_ID

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded'
        let categories: ICategories[] = []
        action.payload.forEach((element: any) => {
          if (element.id === Number(currentCategoryId)) {
            categories.push({
              name: element.name,
              slug: element.slug,
              id: element.id,
            })
          } else if (element.parent === Number(currentCategoryId)) {
            categories.push({
              name: element.name,
              slug: element.slug,
              id: element.id,
              parentCategory: currentCategoryId,
            })
          }
        })
        state.categories = categories
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export default slice.reducer
