import React from 'react'
import { Box, Flex, IconButton, Tooltip } from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'

interface INavigationTooltip {
  tooltipInfo: string
  rightText: string | JSX.Element
  path: string
}
const NavigationTooltip = ({
  tooltipInfo,
  path,
  rightText,
}: INavigationTooltip) => {
  const navigate = useNavigate()
  return (
    <Flex w="100%" alignItems="center">
      <Tooltip
        placement="bottom-end"
        hasArrow
        background="#f7fafc"
        label={
          <Flex p="5px" color="black">
            {tooltipInfo}
          </Flex>
        }
      >
        <IconButton
          variant="ghost"
          aria-label="back"
          size="sm"
          color="black"
          onClick={(event) => {
            event.preventDefault()
            navigate(path)
          }}
          icon={<ArrowBackIcon fill="black" />}
        />
      </Tooltip>
      <Box ml={{ sm: 0, md: '210px' }}>
        <Flex gap="2" alignItems="center" my="3">
          <Box fontSize="2xl" fontWeight="bold" width="100%">
            {rightText}
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

export default NavigationTooltip
