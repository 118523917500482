import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { IPost } from '../../features/posts/types'
import { mobileBreakPoint } from '../Navbar/navbar'

const PostHeader = ({ post }: { post: IPost }) => {
  const [isMobile] = useMediaQuery(mobileBreakPoint)

  const { acf, content } = post
  return (
    <Flex my="3" mb="6" gap="2" flexWrap={isMobile ? 'wrap' : undefined}>
      {acf && acf.obrazek_wyrozniajacy && acf.obrazek_wyrozniajacy.url && (
        <Image
          mt="15px"
          maxW="200px"
          borderRadius="md"
          objectFit="contain"
          src={acf.obrazek_wyrozniajacy.url}
          objectPosition="top"
          alt={`obrazek`}
        />
      )}
      {content && content.rendered && (
        <Text
          textAlign="justify"
          maxW="600px"
          fontWeight="semibold"
          py="2"
          fontSize="sm"
          pr="2"
        >
          {content.rendered
            .replace('&#8211;', '- ')
            .replace(/<\/?[^>]+(>|$)/g, '')}
        </Text>
      )}
    </Flex>
  )
}

export default PostHeader
