import { Box, Flex, Spinner, Text, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IRootState } from '../../features/rootSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchOnePost } from '../../features/post/actions'
import NavigationTooltip from '../NavigationTooltip/navigationTooltip'
import { format } from 'date-fns'
import PostHeader from './header'
import { mobileBreakPoint } from '../Navbar/navbar'
import AttachmentsForPost from './attachments'
import { blueScroll } from '../../utils/scroll'
import FooterForPost from './postFooter'
import { Filters } from '../Filters/filters'
import { emptyQuery } from '../../pages/ListPage/listPage'
import { IPost } from '../../features/posts/types'

enum POST_OPTIONS {
  LOADING,
  EMPTY,
  POST,
}
const currentCategoryId: any = process.env.REACT_APP_CATEGORY_ID

const OnePost = () => {
  const navigate = useNavigate()

  const { id: postId } = useParams()
  const dispatch = useDispatch()
  const { isLoadingOnePost, currentPost } = useSelector(
    (state: IRootState) => state.onePost,
  )
  const { categories } = useSelector((state: IRootState) => state.categories)
  const [isMobile] = useMediaQuery(mobileBreakPoint)

  useEffect(() => {
    if (postId) {
      //@ts-ignore
      dispatch(fetchOnePost(postId))
    }
  }, [postId])

  const postType = useMemo(() => {
    if (isLoadingOnePost) {
      return POST_OPTIONS.LOADING
    } else if (
      currentPost !== undefined &&
      categories &&
      currentPost?.categories.filter(
        (el) => el == process.env.REACT_APP_CATEGORY_ID,
      ).length > 0
    ) {
      return POST_OPTIONS.POST
    }
    return POST_OPTIONS.EMPTY
  }, [isLoadingOnePost])

  const postOptions = {
    [POST_OPTIONS.POST]: () => {
      const tags = categories
        .filter(
          ({ id }) =>
            id != currentCategoryId &&
            currentPost?.categories.some((el: any) => el === id),
        )
        .map(({ name }) => name)

      const currentCategory = (currentPost: IPost): any => {
        return currentPost.categories
          .map((category) => {
            const categoryEl = categories.filter(({ id }) => category === id)
            if (categoryEl.length > 0 && categoryEl[0].parentCategory) {
              return {
                name: categoryEl[0].name,
                slug: categoryEl[0].slug,
                id: categoryEl[0].id,
              }
            } else {
              return false
            }
          })
          .filter((el) => el)
      }
      return currentPost ? (
        <Box pb="5" w="100%" h="100%">
          <NavigationTooltip
            path="/"
            tooltipInfo="Wróć do strony głównej"
            rightText={currentPost?.title?.rendered.replace('&#8211;', '- ')}
          />
          {isMobile && (
            <Filters
              isPost={true}
              isMobile={isMobile}
              onCloseDrawer={() => {}}
              possibleFilters={categories}
              usedFilters={currentCategory(currentPost)}
              setSelectedFilters={() => {}}
              onElementClick={(selected) => {
                const exist =
                  currentPost?.categories.filter(
                    (elId) => elId === selected[0].id,
                  ).length > 0
                if (exist) {
                  return
                }

                const newQuery = {
                  ...emptyQuery,
                  categories: selected,
                }
                navigate(
                  `/?query=${btoa(
                    unescape(encodeURIComponent(JSON.stringify(newQuery))),
                  )}`,
                )
              }}
            />
          )}
          <Flex>
            <Box>
              {!isMobile && (
                <Filters
                  isPost={true}
                  isMobile={isMobile}
                  onCloseDrawer={() => {}}
                  possibleFilters={categories}
                  usedFilters={currentCategory(currentPost)}
                  onElementClick={(selected) => {
                    const exist =
                      currentPost?.categories.filter(
                        (elId) => elId === selected[0].id,
                      ).length > 0
                    if (exist) {
                      return
                    }

                    const newQuery = {
                      ...emptyQuery,
                      categories: selected,
                    }
                    navigate(
                      `/?query=${btoa(
                        unescape(encodeURIComponent(JSON.stringify(newQuery))),
                      )}`,
                    )
                  }}
                  setSelectedFilters={() => {}}
                />
              )}
            </Box>
            <Box
              mr="4"
              maxH={isMobile ? 'calc(100vh - 380px)' : 'calc(100vh - 300px)'}
              overflow="scroll"
              css={blueScroll}
            >
              {tags && tags.length > 0 && (
                <Flex fontSize="xs" textOverflow="ellipsis" overflow="hidden">
                  <Text color="gray.500">Tags: </Text>
                  <Text color="gray.500" ml="1">
                    {tags.join(', ')}
                  </Text>
                </Flex>
              )}
              <Flex flexDirection="column" flex="1">
                {currentPost && currentPost.date && (
                  <Flex fontSize="xs" textOverflow="ellipsis" overflow="hidden">
                    <Text color="gray.500">Informacja prasowa: </Text>
                    <Text color="gray.500" ml="1">
                      {format(new Date(currentPost?.date), 'dd-MM-yyyy')}
                    </Text>
                  </Flex>
                )}
                <PostHeader post={currentPost} />
              </Flex>
              {currentPost.acf.content && (
                <Flex pr="4" fontSize="md" w="100%">
                  <Box
                    textAlign="justify"
                    dangerouslySetInnerHTML={{
                      __html: currentPost.acf.content
                        .replaceAll(
                          '<p>',
                          '<p class="chakra-text" style="text-align: justify;">',
                        )
                        .replaceAll(
                          '<span>',
                          '<span  style="text-align: justify;">',
                        )
                        .replaceAll(
                          'style="',
                          'style="text-align: justify; font-size: 13px;',
                        ),
                    }}
                  />
                </Flex>
              )}
              <AttachmentsForPost post={currentPost} />
              <FooterForPost post={currentPost} />
            </Box>
          </Flex>
        </Box>
      ) : (
        <></>
      )
    },
    [POST_OPTIONS.EMPTY]: () => {
      console.log()
      return (
        <Box w="100%" h="100%" p="5">
          <NavigationTooltip
            path="/"
            tooltipInfo="Wróć do strony głównej"
            rightText=""
          />
          {isMobile && (
            <Filters
              isPost={true}
              isMobile={isMobile}
              onCloseDrawer={() => {}}
              possibleFilters={categories}
              usedFilters={[]}
              setSelectedFilters={() => {}}
            />
          )}
          <Flex h="100%">
            <Box>
              {!isMobile && (
                <Filters
                  isPost={true}
                  isMobile={isMobile}
                  onCloseDrawer={() => {}}
                  possibleFilters={categories}
                  usedFilters={[]}
                  onElementClick={(selected) => {
                    const newQuery = {
                      ...emptyQuery,
                      categories: selected,
                    }
                    navigate(
                      `/?query=${btoa(
                        unescape(encodeURIComponent(JSON.stringify(newQuery))),
                      )}`,
                    )
                  }}
                  setSelectedFilters={() => {}}
                />
              )}
            </Box>
            <Flex w="100%" h="100%">
              Niestety taki post nie istnieje
            </Flex>
          </Flex>
        </Box>
      )
    },
    [POST_OPTIONS.LOADING]: () => (
      <Flex justifyContent="center" alignItems="center" w="100%" h="100%">
        <Spinner size="xl" />
      </Flex>
    ),
  }

  return postOptions[postType]()
}

export default OnePost
