import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { ICategories } from '../categories/slice'

const currentCategoryId: any = process.env.REACT_APP_CATEGORY_ID
const apiUrl: any = process.env.REACT_APP_API + 'posts/'
export const getPostsAPI = (query?: string) => {
  return axios.get(apiUrl + query)
}
export const resultsPerPage = 10

export const fetchPosts = createAsyncThunk(
  'posts/fetchPosts',
  async (query: {
    search: string
    categories: ICategories[]
    page: number
  }) => {
    let apiQuery = '?categories='
    if (query.categories.length > 0) {
      query.categories.forEach(({ id }, index) => {
        apiQuery += `${id}${index + 1 < query.categories.length ? ',' : ''}`
      })
    } else {
      apiQuery += `${currentCategoryId}`
    }
    if (query.search !== '') {
      apiQuery += `&search=${query.search}`
    }
    apiQuery += `&per_page=${resultsPerPage}&page=${query.page}`
    const response = await getPostsAPI(apiQuery)
    // @ts-ignore
    const totalCount = response.headers.get('X-WP-Total')
    return { data: response.data, totalCount, page: query.page }
  },
)
