import { Flex, Text } from '@chakra-ui/react'

export const Footer = ({ ...props }: any) => {
  return (
    <Flex
      w="100%"
      as="footer"
      id="footer"
      {...props}
      py={{ base: '2', md: '3' }}
    >
      <Text
        display="flex"
        w="100%"
        justifyContent="center"
        fontSize="sm"
        text-align="center"
      >
        Copyright © {new Date().getFullYear()} FlyPR
      </Text>
    </Flex>
  )
}
