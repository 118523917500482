import React, { useEffect, useState } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'

import { theme as proTheme } from '@chakra-ui/pro-theme'

import {
  ChakraProvider,
  Flex,
  extendTheme,
  theme as baseTheme,
} from '@chakra-ui/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import '@fontsource-variable/open-sans'
import '@fontsource-variable/spline-sans'

import { PageNotFound } from './pages/PageNotFound/notFound'
import ListPage from './pages/ListPage/listPage'
import store from './store'
import { fetchCategories } from './features/categories/actions'
import PrivateRoute from './PageRoute'
import { IRootState } from './features/rootSlice'
import PostPage from './pages/Post/postPage'

export const theme = extendTheme(
  {
    colors: { ...baseTheme.colors, brand: baseTheme.colors.blue },
  },
  proTheme,
)

const paths = [
  {
    path: '/',
    element: <ListPage />,
  },
  {
    path: '/post/:id',
    element: <PostPage />,
  },
]

export const App = () => {
  const dispatch = useDispatch()
  const { status } = useSelector((state: IRootState) => state.categories)
  useEffect(() => {
    //@ts-ignore
    dispatch(fetchCategories())
  }, [dispatch])
  return (
    <ChakraProvider theme={theme}>
      <Flex
        minH={{ base: 'auto', md: '100vh' }}
        minW={{ base: 'auto', md: '100vw' }}
      >
        <Flex maxW="100%" mx="auto" width="full">
          <BrowserRouter>
            <Routes>
              {paths.map(({ path, element }, index) => {
                return PrivateRoute({
                  loading: status === 'loading',
                  path,
                  element,
                  key: `path_${path}_${index}`,
                })
              })}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </Flex>
      </Flex>
    </ChakraProvider>
  )
}

export const AppWrapper = () => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    document.fonts
      .load('60px Spline Sans Variable')
      .then(() => setIsReady(true))
  }, [])

  return <Provider store={store}>{isReady ? <App /> : null}</Provider>
}
