import React, { PropsWithChildren } from 'react'
import { Container, Flex, Spinner } from '@chakra-ui/react'
import { Route } from 'react-router-dom'
import { Footer } from './components/Footer/footer'
import { Navbar } from './components/Navbar/navbar'

export const loader = (
  <Flex justifyContent="center" w="100%" h="100vh" alignItems="center">
    <Spinner size="xl" />
  </Flex>
)

const ElementWithFooter = ({ children }: PropsWithChildren) => {
  return (
    <Flex
      h="100vh"
      w="100%"
      justifyContent="center"
      css={{
        '.chakra-offset-slide': {
          height: '100%',
          flex: 1,
          alignSelf: 'stretch',
        },
      }}
    >
      <Flex
        maxW="1000px"
        w="100%"
        direction="column"
        justifyContent="space-between"
      >
        <Navbar />
        <Container
          flex="1"
          flexDirection="column"
          maxW="100%"
          display="flex"
          alignItems="center"
          bg="var(--chakra-colors-bg-canvas)"
        >
          {children}
          <Footer />
        </Container>
      </Flex>
    </Flex>
  )
}

const PrivateRoute = ({
  loading,
  path,
  element,
  key,
}: {
  loading: boolean
  path: string
  key: string
  element: any
}) => {
  return loading ? (
    <React.Fragment key={`loading_${key}`}>
      <Route path={path} element={loader} />
    </React.Fragment>
  ) : (
    <React.Fragment key={`loading_${key}`}>
      <Route
        path={path}
        element={<ElementWithFooter>{element}</ElementWithFooter>}
      />
    </React.Fragment>
  )
}

export default PrivateRoute
