import { configureStore } from '@reduxjs/toolkit'
import categoriesReducer from './features/categories/slice'
import postsReducer from './features/posts/slice'
import onePostReducer from './features/post/slice'

const store = configureStore({
  reducer: {
    categories: categoriesReducer,
    posts: postsReducer,
    onePost: onePostReducer,
  },
})

export default store
