import { Box, Divider, Flex } from '@chakra-ui/react'
import React from 'react'
import { IPost } from '../../features/posts/types'

const FooterForPost = ({ post }: { post: IPost }) => {
  const { acf } = post

  return acf.footer ? (
    <Box mt="25px" pr="4">
      <Divider mb="25px" />
      <Flex fontSize="md" w="100%">
        <Box
          dangerouslySetInnerHTML={{
            __html: acf.footer
              .replaceAll(
                '<p>',
                '<p class="chakra-text" style="text-align: justify;">',
              )
              .replaceAll('<span>', '<span  style="text-align: justify;">')
              .replaceAll(
                'style="',
                'style="text-align: justify; font-size: 12px;',
              ),
          }}
        />
      </Flex>
    </Box>
  ) : (
    <></>
  )
}

export default FooterForPost
