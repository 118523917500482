import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const apiUrl: any = process.env.REACT_APP_API + 'categories'
export const getCategoriesAPI = () => {
  return axios.get(apiUrl + '?per_page=100') // Assuming this endpoint returns categories
}
export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async () => {
    const response = await getCategoriesAPI()
    return response.data
  },
)
