import { Flex } from '@chakra-ui/react'
import React from 'react'
import ReactPaginate from 'react-paginate'
import { useAsyncDebounce } from 'react-table'

const Pagination = ({
  maxPage,
  page,
  onPageChange,
}: {
  maxPage: number
  page: number
  onPageChange: (page: number) => void
}) => {
  const PaginationDebounce = useAsyncDebounce((page: number) => {
    onPageChange(page)
  }, 300)

  return (
    <Flex
      sx={{
        '.previous': {
          display: 'none',
        },
        '.next': {
          display: 'none',
        },
        '.page_element': {
          color: 'black',
          background: 'white',
          width: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '30px',
          borderRadius: '10px',
        },
        '.selected': {
          color: 'white',
          background: 'brand.400',
        },
        '.navigation': {
          display: 'flex',
          width: '100%',
          gap: '2px',
          justifyContent: 'center',
        },
        '.navigation li': {
          listStyleType: 'none',
        },
      }}
    >
      <ReactPaginate
        breakLabel="..."
        pageClassName="page_element"
        marginPagesDisplayed={0}
        className="navigation"
        nextLabel="next >"
        onPageChange={(page) => PaginationDebounce(page.selected + 1)}
        pageRangeDisplayed={3}
        pageCount={maxPage}
        initialPage={page}
        previousLabel="< previous"
        disableInitialCallback={true}
      />
    </Flex>
  )
}

export default Pagination
