import React from 'react'
import { ScaleFade } from '@chakra-ui/react'

import { WhiteContainer } from '../../components/WhiteContainer/whiteContainer'

import OnePost from '../../components/OnePost/onePost'
const PostPage = () => {
  return (
    <ScaleFade initialScale={0.001} in={true}>
      <WhiteContainer>
        <OnePost />
      </WhiteContainer>
    </ScaleFade>
  )
}

export default PostPage
