import { Box, Container, Flex, Image } from '@chakra-ui/react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
export const mobileBreakPoint = '(max-width: 850px)'

export const Navbar = () => {
  const navigate = useNavigate()
  return (
    <Box as="section">
      <Box id="navbar" as="nav">
        <Container p="4" maxW="full">
          <Flex justify="space-between">
            <Image
              cursor="pointer"
              src="/flypr_sm.png"
              onClick={() => navigate('/')}
              w="3.5em"
              data-qa__id="navbar_logo"
            />
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}
